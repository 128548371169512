let intercomLoaded = false

const loadIntercomScript = () => {
  if (!intercomLoaded) {
    // Load the Intercom script asynchronously
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.innerHTML = `window.intercomSettings = {
      app_id: '${process.env.INTERCOM_APP_ID || 'l3yzlpad'}',
      alignment: 'right',
    };
    !function(){var t=window,e=t.Intercom;if("function"==typeof e)e("reattach_activator"),e("update",t.intercomSettings);else{var n=document,c=function(){c.c(arguments)};c.q=[],c.c=function(t){c.q.push(t)},t.Intercom=c,setTimeout(function(){!function(){var t=n.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://widget.intercom.io/widget/l3yzlpad";var e=n.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)}()},4e3)}}()
    `
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)

    intercomLoaded = true
  }
}

export const initIntercom = () => {
  loadIntercomScript()
}
